import Vue from 'vue';
import Router from 'vue-router';

import OgvHome from './components/OgvHome';
import Impressum from './components/Impressum.vue';
import Datenschutz from './components/Datenschutz.vue';
import OgvEventRouter from './components/events/OgvEventRouter.vue';
import OgvCalendar from './components/calendar/OgvCalendar.vue';
import OgvNews from './components/news/OgvNews.vue';

import Vorstandschaft from './components/verein/Vorstandschaft.vue';
import OgvKids from './components/verein/OgvKids.vue';
import OgvVorstellung from './components/verein/OgvVorstellung.vue';
import OgvMitgliedschaft from './components/verein/OgvMitgliedschaft.vue';
import OgvStreuobst from './components/themen/OgvStreuobst.vue';
import Gartenzertifizierung from './components/themen/Gartenzertifizierung.vue';
import OgvNewsRouter from "./components/news/OgvNewsRouter";
import OgvGartenpfleger from "./components/verein/OgvGartenpfleger";
import GardensAround from "./components/themen/GardensAround";
import TagDerOffenenGartentuer2024 from "./components/themen/TagDerOffenenGartentuer2024";
import TagDerOffenenGartentuer2022 from "./components/themen/TagDerOffenenGartentuer2022";
import TagDerOffenenGartentuer2023 from "./components/themen/TagDerOffenenGartentuer2023";
import OgvGalleryRouter from "./components/gallery/OgvGalleryRouter";
import OgvWeather from "@/components/weather/OgvWeather";
import KidsSaatgut from "@/components/verein/kids/KidsSaatgut";
import StreuobstMap from "@/components/themen/StreuobstMap";
import Ogv90Jahre90Baeume from "@/components/verein/90jahre/Ogv90Jahre90Baeume";
import Ogv90JahrePflanzenliste from "@/components/verein/90jahre/Ogv90JahrePflanzenliste";
import Ogv90Galerien from "@/components/verein/90jahre/Ogv90Galerien";
import FotoGalerien from "@/components/themen/FotoGalerien";
import FruitTeeMap from "@/components/themen/FruitTeeMap";
import WildbienenStudie from "@/components/themen/wildbienen/WildbienenStudie.vue";



Vue.use(Router);


const routes = [
    { path: '/', component: OgvHome },
    { path: '/impressum', component: Impressum },
    { path: '/datenschutz', component: Datenschutz },
    { path: '/verein/vorstandschaft', component: Vorstandschaft },
    { path: '/verein/OgvKids', name: 'ogvKids', component: OgvKids },
    { path: '/verein/kids/Saatgut2021', name: 'ogvKidsSaatgut2021', component: KidsSaatgut },
    { path: '/verein/OgvVorstellung', name: 'ogvVorstellung', component: OgvVorstellung },
    { path: '/verein/Mitgliedschaft', component: OgvMitgliedschaft },
    { path: '/90jahre',  name: '90Jahre', component: Ogv90Jahre90Baeume },
    { path: '/90jahre/Gehoelzliste', component: Ogv90JahrePflanzenliste },
    { path: '/90jahre/Galerien', name: 'Ogv90Galerien', component: Ogv90Galerien },
    { path: '/verein/Gartenpfleger', component: OgvGartenpfleger},
    { path: '/themen/Streuobst', name: 'streuobst', component: OgvStreuobst },
    { path: '/themen/StreuobstMap', name: 'streuobstMap', component: StreuobstMap },
    { path: '/themen/StreuobstKarte', name: 'streuobstMap2', component: FruitTeeMap },
    { path: '/themen/Gartenzertifizierung', name: 'gartenzertifizierung', component: Gartenzertifizierung },
    { path: '/themen/Gaerten', name: 'gaerten', component: GardensAround },
    { path: '/themen/TagDerOffenenGartentuer2024', name: 'Offenen Gartentür 2024', component: TagDerOffenenGartentuer2024},
    { path: '/themen/TagDerOffenenGartentuer2022', name: 'Offenen Gartentür 2022', component: TagDerOffenenGartentuer2022},
    { path: '/themen/TagDerOffenenGartentuer2023', name: 'Offenen Gartentür 2023', component: TagDerOffenenGartentuer2023},
    { path: '/themen/FotoGalerien', name: 'FotoGalerien', component: FotoGalerien},
    { path: '/themen/wildbienen/Wildbienenstudie', name: 'Wildbienenstudie', component: WildbienenStudie},
    { path: '/calendar', component: OgvCalendar },
    { path: '/news', component: OgvNews},
    { path: '/events/:eventId', name: 'ogvEvent', component: OgvEventRouter, props: true },
    { path: '/news/topic/:topicId', name: 'ogvTopic', component: OgvNewsRouter, props: true },
    { path: '/gallery/:galleryId', name: 'ogvGallery', component: OgvGalleryRouter, props: true },
    { path: '/weather', component: OgvWeather}
];


export default new Router({
  routes
});
