<template>
   <header>
    <b-navbar sticky fixed toggleable="md" type="dark" variant="primary">

      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand to="/">
          <img src="logo_ogv_weiss.svg" width="40px" class="mr-2" alt="OGV Logo">OGV Schießen
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">

        <b-navbar-nav>
          <b-nav-item-dropdown text="Verein" right>
            <b-dropdown-item to="/verein/OgvVorstellung">OGV Vorstellung</b-dropdown-item>
            <b-dropdown-item to="/verein/vorstandschaft">Vorstandschaft</b-dropdown-item>
            <b-dropdown-item to="/verein/OgvKids">OGV Kids</b-dropdown-item>
            <b-dropdown-item to="/verein/Mitgliedschaft">Mitglied werden</b-dropdown-item>
            <b-dropdown-item to="/verein/Gartenpfleger">Gartenpfleger</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Themen" right>
            <b-dropdown-group id="dropdown-group-1" header="Streuobst">
              <b-dropdown-item to="/themen/Streuobst">Baumpaten</b-dropdown-item>
              <b-dropdown-item to="/themen/StreuobstMap">Streuobst Karte Neu-Ulm</b-dropdown-item>
              <b-dropdown-item to="/themen/StreuobstKarte">Streuobst Karte Schwaben</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group id="dropdown-group-1" header="Artenvielfalt">
              <b-dropdown-item to="/themen/wildbienen/Wildbienenstudie">Studie Wildbienen Neu-Ulm</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group id="dropdown-group-1" header="Gärten">
              <b-dropdown-item to="/themen/Gartenzertifizierung">Zertifizierung 'Naturgarten'</b-dropdown-item>
              <b-dropdown-item to="/themen/Gaerten">G&auml;rten in der Umgebung</b-dropdown-item>
              <b-dropdown-item to="/themen/TagDerOffenenGartentuer2022">offenen Gartentür 2022</b-dropdown-item>
              <b-dropdown-item to="/themen/TagDerOffenenGartentuer2023">offenen Gartentür 2023</b-dropdown-item>
              <b-dropdown-item to="/themen/TagDerOffenenGartentuer2024">offenen Gartentür 2024</b-dropdown-item>
              <b-dropdown-item to="/themen/FotoGalerien">Foto Galerien</b-dropdown-item>
            </b-dropdown-group>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="90 Jahre" right>
            <b-dropdown-item to="/90jahre">90 Jahre - 90 Bäume</b-dropdown-item>
            <b-dropdown-item to="/90jahre/Gehoelzliste">Gehölzliste</b-dropdown-item>
            <b-dropdown-item to="/90jahre/Galerien">Galerien</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>

      </b-collapse>
        <b-nav-text>
            <b-link class="mr-3" to="/themen/Gaerten"><font-awesome-icon icon="leaf" />   </b-link>
            <b-link class="mr-3" to="/calendar"><font-awesome-icon icon="calendar" />   </b-link>
            <b-link to="/news"> <font-awesome-icon icon="newspaper" /></b-link>
        </b-nav-text>



    </b-navbar>
 </header>
</template>

<script>
 export default {
  name: 'ogv-header',
}
</script>
