<template>
  <div class="container">
    <ogv-page-header page-title="Studie zu Wildbienenvorkommen im Landkreis Neu-Ulm "></ogv-page-header>
    <div class="row mt-4">
      <div class="col-sm-8">
        <p><i>Auszug aus der Studie: Erfassung von Wildbienenvorkommen in ausgewählten ehemaligen Abbaustellen
          der Landkreise Donau-Ries und Neu-Ulm im Rahmen des Biodiversitätsprojektes
          "Wildbienen in Schwaben"</i></p>
        <p>Mit freundlicher Genehmigung des Landratsamt Neu-Ulm</p>
        <ogv-markdown markdownurl='themen/wildbienen/Anlass.md'></ogv-markdown>
      </div>
      <div class="col-sm-4 ">
        <img src='themen/wildbienen/StudieWildbienen2024.jpg' class="img-thumbnail" alt="Wildbienen Studie"/>
      </div>
    </div>
    <div class="row mt-4">
      <ogv-markdown markdownurl='themen/wildbienen/WildbienenNeuUlm.md'></ogv-markdown>
    </div>
    <div class="row mt-4">
        <ogv-markdown markdownurl='themen/wildbienen/studieIngstetten1.md'></ogv-markdown>
    </div>
    <div class="row mt-4">
      <div class="col-sm-8 ">
        <ogv-markdown markdownurl='themen/wildbienen/studieIngstetten2.md'></ogv-markdown>
      </div>
      <div class="col-sm-4 ">
        <img src='themen/wildbienen/HabitatmanagementSchleebuch.jpg' class="img-thumbnail" alt="Habitatmanagement Schleebuch"/>
      </div>
    </div>
    <div class="row mt-4">
        <ogv-markdown markdownurl='themen/wildbienen/studieSchiessen1.md'></ogv-markdown>
    </div>
    <div class="row mt-4">
      <div class="col-sm-4 ">
        <img src='themen/wildbienen/HabitatmanagementSchiessen.jpg' class="img-thumbnail"
             alt="Habitatmanagement Schiessen"/>
      </div>
      <div class="col-sm-8 ">
        <ogv-markdown markdownurl='themen/wildbienen/studieSchiessen2.md'></ogv-markdown>
      </div>
    </div>

    <div>
      <h3> Zusätzliche Links</h3>
      <b-link target="_blank" href="https://www.wildbienenzentrum.de/wp-content/uploads/2024/07/Schwenninger-et-al.-2024-Positionspapier-Zur-Honigbienenhaltung-in-Naturschutzgebieten.pdf">Positionspapier Zur Honigbienenhaltung in Naturschutzgebieten</b-link>


    </div>

  </div>
</template>

<script>
export default {
  name: 'wildbienen-studie',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>

